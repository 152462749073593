import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client/core'
import {setContext} from '@apollo/client/link/context';

const accessToken = localStorage.accessToken
const urlApi = process.env.VUE_APP_API_URL;

const httpLink = createHttpLink({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Accept': 'application/json',
    'Authorization': `Bearer ${accessToken}`,
  },
  fetchOptions: {
    credentials: 'include',
  },
  uri: `${urlApi}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
    }
  }
});

export const apolloClient = new ApolloClient({
  credentials: 'include',
  link: ApolloLink.from([httpLink, authLink]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
})
